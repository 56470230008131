import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Apex Auto Enclave
			</title>
			<meta name={"description"} content={"Відкрийте для себе Apex Auto Enclave, найкраще місце для любителів екзотики та тюнінгу автомобілів"} />
			<meta property={"og:title"} content={"Apex Auto Enclave"} />
			<meta property={"og:description"} content={"Відкрийте для себе Apex Auto Enclave, найкраще місце для любителів екзотики та тюнінгу автомобілів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
		</Components.Header>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="column" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					width="100%"
				>
					Найкраще місце для любителів екзотики та тюнінгу автомобілів
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				height="auto"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="60px 0px 0px 0px"
					color="--light"
					font="--lead"
					width="100%"
				>
					Наш клуб є притулком для тих, хто цінує мистецтво автомобільної досконалості. Незалежно від того, вдосконалюєте ви свій поточний шедевр чи шукаєте зв’язків у спільноті, яка шанує швидкість і витонченість, Apex стане вашою ідеальною піт-стопом.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Про Apex
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Apex Auto Enclave – це більше, ніж автомобільний клуб – це рух. Ми створюємо середовище, де ентузіасти можуть демонструвати свої цінні автомобілі, ділитися досвідом та брати участь у захоплюючих дорожніх подіях. У Apex кожен член є невід’ємною частиною престижної автомобільної спадщини.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11:22:30.222Z"
					width="100%"
					max-height="293px"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-11.jpg?v=2024-05-30T11%3A22%3A30.222Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11:22:30.230Z"
					max-height="auto"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline2"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Переваги Apex
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					Преміум-події: Отримуйте доступ до ексклюзивних автосалонів і приватних зустрічей, присвячених світу високопродуктивних автомобілів.
					<br />
					<br />
					Обмін досвідом: на наших регулярних семінарах навчайтеся у провідних експертів із персоналізації автомобіля та налаштування продуктивності.
					<br />
					<br />
					Жвава мережа: спілкуйтеся з друзями-любителями автомобілів, які так само захоплюються досконалим водінням, як і ви.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="80%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
				>
					Відкрийте для себе наші пропозиції
				</Text>
				<Text
					color="--light"
					font="--base"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					text-align="left"
				>
					У Apex Auto Enclave ми присвячуємо себе забезпеченню неперевершених вражень для любителів екзотики та тюнінгованих автомобілів. Наші послуги створені, щоб посилити вашу пристрасть до автомобілів, пропонуючи як практичні, так і ексклюзивні переваги, адаптовані до ваших потреб. Дослідіть широту того, що ми пропонуємо, і дізнайтеся, як ми задовольняємо кожен аспект вашого автомобільного ентузіазму.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11:22:30.214Z"
					max-height="400px"
					width="100%"
					object-position="center"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-8.jpg?v=2024-05-30T11%3A22%3A30.214Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					text-transform="uppercase"
				>
					Майстер-класи з налаштування
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Комплекти кузова та фарбування: перетворіть свій автомобіль за допомогою власних комплектів кузова та нового шару фарби, створеного так, щоб виділятися.
Налаштування продуктивності: створіть ідеальне налаштування за допомогою наших розширених послуг налаштування, покращуючи потужність і керованість відповідно до вашого стилю водіння.
Деталізаційні послуги. Зберігайте свій автомобіль у первозданному вигляді завдяки нашим деталізаційним послугам, які охоплюють усе: від шампуню для салону до високоякісних воскових поліролей.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11:22:30.230Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-10.jpg?v=2024-05-30T11%3A22%3A30.230Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
					text-transform="uppercase"
				>
					Ексклюзивні події водіння
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Track Days: отримайте доступ до місцевих і міжнародних гоночних трас для безпечного та захоплюючого водіння.
Мальовничі подорожі: приєднуйтесь до нас в організованих подорожах, які проведуть вас одними з найкрасивіших ландшафтів.
Демонстраційні події: демонструйте свій автомобіль на гучних автомобільних виставках і приватних заходах, які проводить клуб.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11:22:30.212Z"
					max-height="400px"
					width="100%"
					object-position="center"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-5.jpg?v=2024-05-30T11%3A22%3A30.212Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					text-transform="uppercase"
				>
					Технічні семінари та клініки
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Найновіші автомобільні технології: будьте попереду з семінарами про останні автомобільні технології та тенденції.
Технічне обслуговування своїми руками: навчайтеся у експертів із практичними клініками, які охоплюють базове та розширене обслуговування автомобіля.
Безпека та продуктивність: дізнайтеся, як підвищити продуктивність вашого автомобіля, забезпечуючи максимальну безпеку.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11:22:30.194Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-2.jpg?v=2024-05-30T11%3A22%3A30.194Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
					text-transform="uppercase"
				>
					Більше для дослідження
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					У цьому огляді лише огляд того, що пропонує Apex Auto Enclave. Ми запрошуємо вас зв’язатися з нами або відвідати нас особисто, щоб ознайомитися з усіма нашими пропозиціями. Незалежно від того, чи хочете ви оновити свій автомобіль чи спілкуєтеся з іншими автолюбителями, Apex тут, щоб покращити вашу автомобільну подорож.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});